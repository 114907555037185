import { combineReducers } from "redux";
import navbarReducer from "./navbar/navbarReducer";
import modalReducer from "./modal/modalReducer";
import propertyReducer from "./projects/projectReducer"

//Creating the root reducer and defining the starredRepos reducer
const rootReducer = combineReducers({
  navbar: navbarReducer,
  modal: modalReducer,
  property: propertyReducer,
  // Add your reducers here
});

export default rootReducer;

import React, { useState } from "react";
import "./ContactDetails.css";
import myImage from "../../../assets/images/contact.svg.png";
import { useLocation } from "react-router";
import trash from "../../../assets/images/trash.svg";
import Button from "../../../components/common/button/Button";
import restHelper from "../../../helpers/RestHelper";
import appConfig from "../../../config.json";
import axios from "axios";
import { BACKEND_URL } from "../../../constants";

function ContactDetails() {
  const backEndUrl = `${BACKEND_URL}`;

  const location = useLocation();
  const data = location?.state?.data;

  const [itemImage, setItemImage] = useState(
    location?.state?.data.profile_picture
  );

  const handleImageChange = (e) => {
    if (e.target.files[0]) changeImages(e.target.files[0]);
  };

  const deleteImage = () => {
    changeImages();
  };

  const changeImages = (image) => {
    const url =
      backEndUrl + appConfig.backend.routes.contacts.updatecontactImages;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(
        url,
        {
          contact_id: location?.state?.data?.id,
          profile_picture: image ? image : {},
        },
        config
      )
      .then(() => {
        setItemImage(image ? URL.createObjectURL(image) : null);
      });
  };

  return (
    <>
      <div className="main-container-contactDetails">
        <div className="section-main-container-contactDetails">
          {/* 1st Row Contact info and Address */}
          <div className="section-row-container-contactDetails">
            <div className="section-container-contactDetails">
              <div className="section-container-contactDetails-contactInfo">
                {/* Profile Picture */}
                <div className="column-contactInfo-profile-picture">
                  <div>
                    <img
                      src={itemImage ? itemImage : myImage}
                      alt="Profile Picture"
                    />
                  </div>
                  <div className="image-controls">
                    <label class="image-btn">
                      <input
                        type="file"
                        id="propertyImage"
                        accept="image/*,.pdf"
                        onChange={(e) => handleImageChange(e)}
                      />
                      Edit Image
                    </label>
                    {itemImage !== null ? (
                      <Button
                        className={"delete-image-btn-items"}
                        buttonText={
                          <img
                            style={{ height: "75%" }}
                            src={trash}
                            alt="Delete Image"
                          />
                        }
                        onClick={() => deleteImage()}
                      />
                    ) : null}
                  </div>
                </div>
                {/* Contact Info */}
                <div className="column-contactInfo">
                  <h2>
                    {" "}
                    {data.first_name} {data.last_name} Info
                  </h2>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Email:</strong> {data.email}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Phone:</strong> {data.phone_number}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Category:</strong> {data.category.name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Company:</strong> {data.company.name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Contact Type:</strong> {data.type}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Website:</strong>{" "}
                      {data.website !== null ? data.website : ""}
                    </p>
                  </div>
                </div>
                {/* Contact Address */}
                <div className="column-contactInfo">
                  <h2>
                    {" "}
                    {data.first_name} {data.last_name} Address
                  </h2>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Street Number:</strong> {data.street_number}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Street Name:</strong> {data.street_name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>City:</strong> {data.city}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>State:</strong> {data.state}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Country:</strong> {data.country}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Zip Code:</strong> {data.zip_code}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 2nd Row Bank info and Address */}
          <div className="section-row-container-contactDetails">
            <div className="section-container-contactDetails">
              <div className="section-container-contactDetails-bankInfo">
                {/* Bank Address */}
                <div className="column-contactInfo">
                  <h2>
                    {" "}
                    {data.first_name} {data.last_name} Bank Address
                  </h2>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Street Number:</strong> {data.bank_street_number}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Street Name:</strong> {data.bank_street_name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>City:</strong> {data.bank_city}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>State:</strong> {data.bank_state}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Country:</strong> {data.bank_country}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Zip Code:</strong> {data.bank_zip_code}
                    </p>
                  </div>
                </div>
                {/* Bank Info */}
                <div className="column-contactInfo">
                  <h2>
                    {" "}
                    {data.first_name} {data.last_name} Bank Info
                  </h2>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Bank Name:</strong>{" "}
                      {data.contact_bank !== null ? data.contact_bank.name : ""}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Account Name:</strong>{" "}
                      {data.contact_bank_account_name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Account Type:</strong>{" "}
                      {data.contact_bank_account_type}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Account Number:</strong>{" "}
                      {data.contact_bank_account_number}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Routing Number:</strong>{" "}
                      {data.contact_bank_routing_number}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactDetails;

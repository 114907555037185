import React from "react";
import PropTypes from "prop-types";
import "./SelectInput.css";

function SelectInput({
  value,
  onChange,
  name,
  label,
  options,
  required,
  disabled,
  error,
}) {
  const handleOnChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = options[selectedIndex - 1]; // -1 to account for the default empty option
    onChange(e, selectedOption); // Pass the event and the selected option object
  };

  return (
    <div className="select-input-container" style={{ color: "black" }}>
      <label
        className="input-label"
        htmlFor={name}
        style={{ fontFamily: "Gotham Rounded" }}
      >
        {label} {required && "*"}
      </label>
      <select
        name={name}
        value={value}
        className="select-input"
        onChange={handleOnChange}
        required={required}
        disabled={disabled}
        style={error.length > 0 ? { border: "1px solid red" } : {}}
      >
        <option value="" selected hidden>{`Please select the ${label}`}</option>
        {!required && <option></option>}
        {options &&
          options.map((option, index) => (
            <option
              value={option.value}
              key={index}
              style={{ fontFamily: "Gotham Rounded, sans-serif" }}
            >
              {option.name}
            </option>
          ))}
      </select>
      <div className="input-error">{error}</div>
    </div>
  );
}
SelectInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
};

SelectInput.defaultProps = {
  value: "",
  onChange: () => {},
  name: "",
  label: "",
  options: PropTypes.any,
  disabled: false,
  required: false,
  error: "",
};

export default SelectInput;

import React from "react";
import PropTypes from "prop-types";
import "./UploadImageInput.css";

function UploadImageInput({
  name,
  label,
  required,
  disabled,
  multiple,
  onclick,
  props,
  capture,
}) {
  return (
    <div className="input-container" style={{ color: "black" }}>
      <label
        className="input-label"
        htmlFor={name}
        style={{ fontFamily: "Gotham Rounded" }}
      >
        {label}
      </label>
      <input
        type="file"
        id="image"
        accept="image/*,.pdf"
        multiple={multiple}
        disabled={disabled}
        required={required}
        onChange={(e) => onclick(e, "mutiple")}
        {...props}
        capture={capture}
      />
    </div>
  );
}

UploadImageInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  capture: PropTypes.bool,
};

UploadImageInput.defaultProps = {
  name: "",
  label: "",
  required: false,
  disabled: false,
  multiple: false,
  capture: false,
};

export default UploadImageInput;

import { applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { epicsMiddlewares, runEpicsMiddlewares } from "./rootEpic";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const middleware = [thunk];

//Creating the redux store and setting the thunk middleware for async calls
const store = configureStore(
  {
    reducer: rootReducer,
    middleware: [...epicsMiddlewares],
  },
  composeWithDevTools(applyMiddleware(...middleware))
);

runEpicsMiddlewares();

export default store;

import { createEpicMiddleware } from 'redux-observable';
import appConfig from "../config.json";
import restHelper from "../helpers/RestHelper";
import { propertyEpics } from "./projects/projectEpic";

const propertEpicMiddleware = createEpicMiddleware({
  dependencies: {
    rest: restHelper,
    appConfig: appConfig,
  },
});

export const epicsMiddlewares = [propertEpicMiddleware];

export const runEpicsMiddlewares = () => {
    propertEpicMiddleware.run(propertyEpics);
};

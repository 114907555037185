import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Input.css";

function Input({
  type,
  value,
  setValue,
  name,
  label,
  required,
  disabled,
  error,
  rows,
  max,
}) {
  const [inputError, setInputError] = useState("");
  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (type === "date") {
      const today = new Date();
      const selectedDate = new Date(inputValue);

      if (selectedDate > today) {
        setInputError("Date cannot be in the future.");
      } else {
        setInputError("");
      }
    }

    setValue(inputValue);
  };

  return (
    <div className="input-container" style={{ color: "black" }}>
      <label
        className="input-label"
        htmlFor={name}
        style={{ fontFamily: "Gotham Rounded" }}
      >
        {label} {required && "*"}
      </label>
      {type === "text-area" ? (
        <textarea
          rows={rows}
          className={"input -textarea"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          name={name}
          required={required}
          disabled={disabled}
          style={error.length > 0 ? { border: "1px solid red" } : {}}
        />
      ) : (
        <input
          className={"input"}
          type={type}
          value={value}
          onChange={handleChange}
          name={name}
          required={required}
          disabled={disabled}
          max={max}
          style={
            inputError || error.length > 0 ? { border: "1px solid red" } : {}
          }
        />
      )}
      <div className="input-error">{inputError || error}</div>
    </div>
  );
}

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  rows: PropTypes.number,
  max: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
  value: "",
  setValue: () => {},
  name: "",
  label: "",
  required: false,
  disabled: false,
  error: "",
  rows: 15,
  max: "",
};

export default Input;

import {
    GET_PROPERTY_IMAGES,
    DELETE_PROPERTY_IMAGES,
    FILL_PROPERTY_IMAGES,
    UPDATE_PROPERTY_IMAGES,
    UPDATE_PROPERTY_NOTES,
    FILL_PROPERTY_NOTES,
  } from "./projectTypes";

  export const deletePropertyImages = (payload) => {
    return {
      type: DELETE_PROPERTY_IMAGES,
      payload,
    };
  };

  export const updatePropertyImages = (payload) => {
    return {
      type: UPDATE_PROPERTY_IMAGES,
      payload,
    };
  };
  

  export const getPropertyImages = () => {
    return {
      type: GET_PROPERTY_IMAGES,
    };
}

export const fillPropertyImages = (payload) => {
    return {
      type: FILL_PROPERTY_IMAGES,
      payload,
    };
}

export const updatePropertyNotes = (payload) => {
    return {
      type: UPDATE_PROPERTY_NOTES,
      payload,
    };
}

export const fillPropertyNotes = (payload) => {
    alert(payload.data.error_message)
    return {
      type: FILL_PROPERTY_NOTES,
      payload,
    };
}
// Error Handling Errors
export const EMAILVALIDATION = "Email must be in the correct email format";
export const MOBILEVALIDATION = "Mobile must be 11 numbers only";
export const MOBILEVALIDATION15DIGITS =
  "Mobile must be less than 15 numbers only";
export const MOBILEVALIDATIONUSA =
  "Phone Number must be in Format (XXX)XXX-XXXX and 10 numbers only";
export const BEGINWITHZEROVALIDATION = "Mobile must begin with 01xxxxxxxxx";
export const SAMEPASSWORDVALIDATION = "Password is not similar";
export const PASSWORDVALIDATION =
  "Password must contain atleast one letter and one number";
export const PASSWORDLENGTHVALIDATION = "Password less than 4 characters"

export const PASSWORDLETTERVALIDATION =
  "Password must contain atleast one letter"

export const PASSWORDNUMBERVALIDATION =
  "Password must contain atleast one number"

// Validation Logic
export const ValidateFields = (input) => {
  return !input || input === "" ? false : true;
};

export const ValidateInputs = (input) => {
  return !input.id || (input.id === "" && !input.name) || input.name === ""
    ? false
    : true;
};

export const ValidatePhoneNumber = (phoneNumber) => {
  const beginWithZeroRegex = /^[0][1][0-9]*$/;
  const digitsRegex = /^\d{11}$/;
  if (phoneNumber.match(beginWithZeroRegex)) {
  } else {
    return BEGINWITHZEROVALIDATION;
  }
  if (phoneNumber.match(digitsRegex)) {
  } else {
    return MOBILEVALIDATION;
  }
  return true;
};

export const validatePhoneNumberTo15Digits = (input) => {
  const regex = /^\d{1,15}$/;
  return regex.test(input);
};
export const ValidatePhoneNumberUSA = (phoneNumber) => {
  const bracketsDigitsRegex =
    /^\(\d{3}\)\d{3}-\d{4}$/.test(phoneNumber) &&
    /^\d{10}$/.test(phoneNumber.replace(/\D/g, ""));
  return bracketsDigitsRegex;
};

export const ValidateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const ValidatePassword = (password, password2) => {
  if (password === password2) {
  } else {
    return SAMEPASSWORDVALIDATION;
  }
  if(password.length < 4){
    return PASSWORDLENGTHVALIDATION;
  }
  const letterRegex = /[a-zA-Z]/;
  const numberRegex = /[0-9]/;

  if(!password.match(letterRegex)){
    return PASSWORDLETTERVALIDATION;
  }

  if(!password.match(numberRegex)){
    return PASSWORDNUMBERVALIDATION;
  }

  return true;
};

export const ValidateNumberOrDecimal = (input) => {
  const regex = /^\d+(\.\d+)?$/;
  return regex.test(input);
};

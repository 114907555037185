import React, { useEffect, useState } from "react";
import Button from "../../../components/common/button/Button";
import SelectInput from "../../../components/common/selectInput/SelectInput";
import TextField from "../../../components/common/textfield/TextField";
import {
  ValidateFields,
  ValidateInputs,
  ValidateNumberOrDecimal,
} from "../../../utils/formValidation";
import { itemTypeOptions } from "../../../consts/dropDownOptions/itemsOptions";
import { routeNames } from "../../../consts/routesNames";
import { useLocation, useNavigate, useParams } from "react-router";
import "./AddEditItems.css";
import UploadImageInput from "../../../components/common/uploadImageInput/UploadImageInput";
import { useDispatch } from "react-redux";
import { openModal } from "../../../redux/modal/modalActions";
import { getCookie } from "../../../utils/cookies";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";
import { getAllPhases } from "../../../apis/phasesApis/getAllPhases";
import { getAllUnits } from "../../../apis/itemsApis/getAllUnits";
import { getAllContacts } from "../../../apis/contactsApis/getAllContacts";
import { getSubPhasesbyId } from "../../../apis/phasesApis/getSubPhasesById";
import { createItem } from "../../../apis/itemsApis/createItem";
import { editItem } from "../../../apis/itemsApis/editItem";

function AddEditItems() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = getCookie("userId");

  const { id: itemId } = useParams();
  const data = location?.state?.data;
  const editable = data && Object.entries(data).length > 0;

  const [phasesData, setPhasesData] = useState([]);
  const [subPhasesData, setSubPhasesData] = useState([]);
  const [suppliersData, setSuppliersData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);

  // === Contact Info States ===
  const [name, setName] = useState("");
  const [phase, setPhase] = useState({ id: "", name: "" });
  const [subPhase, setSubPhase] = useState({ id: "", name: "" });
  const [supplier, setSupplier] = useState({ id: "", name: "" });
  const [unit, setUnit] = useState({ id: "", name: "" });
  const [type, setType] = useState(""); // Fixed in the BE Model and FE as Options
  const [price, setPrice] = useState(null);
  const [SKU, setSKU] = useState("");
  const [phaseSelected, setPhaseSelected] = useState(false);
  const [itemPicture, setItemPicture] = useState("");

  // Load Options to DropDown

  useEffect(() => {
    const getPhases = async () => {
      try {
        const allPhasesRes = await getAllPhases();
        if (allPhasesRes.error_code === 2 || allPhasesRes.error_code === 3) {
          dispatch(
            openModal(
              <ResponseModal text={allPhasesRes.error_message} isError={true} />
            )
          );
        } else {
          setPhasesData(allPhasesRes.res.data);
        }
      } catch (error) {
        console.error("Error fetching Phases:", error);
      }
    };
    getPhases();
  }, []);

  useEffect(() => {
    const getUnits = async () => {
      try {
        const allUnitRes = await getAllUnits();
        if (allUnitRes.error_code === 2 || allUnitRes.error_code === 3) {
          dispatch(
            openModal(
              <ResponseModal text={allUnitRes.error_message} isError={true} />
            )
          );
        } else {
          setUnitsData(allUnitRes.res.data);
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };
    getUnits();
  }, []);

  useEffect(() => {
    const getSuppliers = async () => {
      try {
        const allContactRes = await getAllContacts();
        if (allContactRes.error_code === 2 || allContactRes.error_code === 3) {
          dispatch(
            openModal(
              <ResponseModal
                text={allContactRes.error_message}
                isError={true}
              />
            )
          );
        } else {
          const suppliersData = allContactRes.res.data.map((contact) => ({
            id: contact.id,
            name: `${contact.first_name} ${contact.last_name ? contact.last_name : ''}`,
          }));
          setSuppliersData(suppliersData);
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };
    getSuppliers();
  }, []);

  const getSubPhasesByPhaseId = async (phaseId) => {
    try {
      const allSubPhasesRes = await getSubPhasesbyId(phaseId);
      if (
        allSubPhasesRes.error_code === 2 ||
        allSubPhasesRes.error_code === 3
      ) {
        dispatch(
          openModal(
            <ResponseModal
              text={allSubPhasesRes.error_message}
              isError={true}
            />
          )
        );
      } else {
        setSubPhasesData(allSubPhasesRes.res.data);
      }
    } catch (error) {
      console.error("Error fetching Sup Phases:", error);
    }
  };

  // Set All States
  useEffect(() => {
    if (editable) {
      setName(data.name);
      setPhase(data.phase);
      setSubPhase(data.sub_phase);
      setPhaseSelected(true);
      getSubPhasesByPhaseId(data.phase.id);
      setSupplier(data.supplier);
      setSupplier((prevSupplier) => ({
        ...prevSupplier,
        name: `${data.supplier.first_name} ${data.supplier.last_name}`,
      }));
      setUnit(data.unit);
      setType(data.type);
      setPrice(data.price);
      setSKU(data.SKU);
      setItemPicture(data.item_picture);
    }
  }, []);

  // === Create - Edit ReqBody ====
  const reqBody = () => {
    const baseRequestBody = {
      name: name,
      type: type,
      SKU: SKU,
      price: price,
      phase_id: phase.id, // Have Logic
      sub_phase_id: subPhase.id,
      unit_id: unit.id,
      supplier_id: supplier.id,
      item_picture: itemPicture,
    };
    if (editable) {
      return {
        ...baseRequestBody,
        item_id: itemId,
        updated_by_user_id: userId,
      };
    } else {
      return {
        ...baseRequestBody,
        created_by_user_id: userId,
      };
    }
  };

  // === Add Handlers ===
  const addItemHandler = async () => {
    const isValidated = addEditItemValidation();
    if (isValidated) {
      const createItemRes = await createItem(reqBody());
      if (createItemRes.error_code === 1) {
        alert("Item Created Successfully");
        navigate(routeNames.ITEMS);
      }
    }
  };

  // === Edit Handlers ===
  const editItemHandler = async (e) => {
    e.preventDefault();
    const isValidated = addEditItemValidation();
    if (isValidated) {
      const editItemRes = await editItem(reqBody());
      if (editItemRes.error_code === 1) {
        alert("Item Updated Successfully");
        navigate(routeNames.ITEMS);
      }
    }
  };

  // === Image Handler ===
  const handleImageChange = (e) => {
    setItemPicture(e.target.files[0]);
  };

  // === Form Validation ===
  const addEditItemValidation = () => {
    if (!ValidateFields(name)) {
      alert("Item Name is required");
      return false;
    }
    if (!ValidateInputs(phase)) {
      alert("Phase is required");
      return false;
    }
    if (!ValidateInputs(subPhase)) {
      alert("Sub Phase is required");
      return false;
    }
    if (!ValidateInputs(supplier)) {
      alert("Supplier is required");
      return false;
    }
    if (!ValidateInputs(unit)) {
      alert("Unit is required");
      return false;
    }
    if (!validatePrice()) {
      return false;
    }
    return true;
  };

  const handlePriceChange = (value) => {
    setPrice(value);
  };

  const validatePrice = () => {
    if (price === null || price === "") {
      return true;
    }
    if (!ValidateNumberOrDecimal(price)) {
      alert("Price must be a valid number or decimal");
      return false;
    }
    return true;
  };

  return (
    <>
      <div className="main-container-addEditItems">
        {/* Inputs Container */}
        <div className="form-main-container-addEditItems">
          {/* Item Info */}

          <div className="form-column-addEditItems">
            <div className="form-card-text-container-addEditItems">
              Item Info
            </div>
            <div className="form-card-input-container-addEditItems">
              {/* Item Info Input fields */}
              <div className="labeltext-container-addEditItems">
                <TextField
                  label={"Name"}
                  name="name"
                  type="text"
                  setValue={setName}
                  value={name}
                  placeHolder=" Name ...."
                  isRequired={true}
                />
              </div>
              <div className="labeltext-container-addEditItems">
                <SelectInput
                  value={phase.name}
                  onChange={(e, newValue) => {
                    setPhase(newValue);
                    getSubPhasesByPhaseId(newValue.id);
                    setPhaseSelected(true);
                  }}
                  options={phasesData}
                  label={"Phase"}
                  name="phase"
                  // disabled={editable ? true : false}
                  required={true}
                />
              </div>
              <div className="labeltext-container-addEditItems">
                <SelectInput
                  value={subPhase.name}
                  onChange={(e, newValue) => {
                    setSubPhase(newValue);
                  }}
                  options={subPhasesData}
                  label={"Sub Phase"}
                  name="sub_phase"
                  disabled={phaseSelected ? false : true}
                  required={true}
                />
              </div>
              <div className="labeltext-container-addEditItems">
                <SelectInput
                  value={supplier.name}
                  onChange={(e, newValue) => {
                    setSupplier(newValue);
                  }}
                  options={suppliersData}
                  label={"Supplier"}
                  name="supplier"
                  required={true}
                />
              </div>
              <div className="labeltext-container-addEditItems">
                <SelectInput
                  value={unit.name}
                  onChange={(e, newValue) => {
                    setUnit(newValue);
                  }}
                  options={unitsData}
                  label={"Unit"}
                  name="unit"
                  // disabled={editable ? true : false}
                  required={true}
                />
              </div>
              <div className="labeltext-container-addEditItems">
                <SelectInput
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  options={itemTypeOptions}
                  label={"Type"}
                  name="type"
                  // disabled={editable ? true : false}
                  required={true}
                />
              </div>
              <div className="labeltext-container-addEditItems">
                <TextField
                  label={"Price"}
                  name="price"
                  type="text"
                  setValue={handlePriceChange}
                  value={price === null ? "" : price}
                  placeHolder=" Price ...."
                  isRequired={true}
                />
              </div>
              <div className="labeltext-container-addEditItems">
                <TextField
                  label={"SKU"}
                  name="SKU"
                  type="text"
                  setValue={setSKU}
                  value={SKU}
                  placeHolder=" SKU ...."
                />
              </div>
              {!editable && (
                <div className="labeltext-container-addEditItems">
                  <UploadImageInput
                    name="Item Picture"
                    label="Item Picture"
                    onclick={(e) => handleImageChange(e)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Buttons Container */}
        <div className="button-main-container-addEditItems">
          <div className={"save-create-buttons-container"}>
            {editable ? (
              <div className="save-button-container">
                <Button
                  buttonText={"Save"}
                  onClick={(e) => editItemHandler(e)}
                />
              </div>
            ) : (
              <div className="create-button-container">
                <Button
                  buttonText={"Create"}
                  type="submit"
                  onClick={() => addItemHandler()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditItems;

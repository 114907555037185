import { ofType, combineEpics } from "redux-observable";
import {
  distinctUntilChanged,
  switchMap,
  catchError,
  withLatestFrom,
} from "rxjs/operators";
import { from, EMPTY, of } from "rxjs";
import axios from "axios";
import { BACKEND_URL } from "../../constants";

import {
  getPropertyImages,
  fillPropertyImages,
  fillPropertyNotes,
} from "./projectsAction";
import {
  GET_PROPERTY_IMAGES,
  DELETE_PROPERTY_IMAGES,
  UPDATE_PROPERTY_IMAGES,
  UPDATE_PROPERTY_NOTES,
} from "./projectTypes";

const url = `${BACKEND_URL}`

export const getPropertyImagesEpic = (action$, state$, { rest, appConfig }) =>
  action$.pipe(
    ofType(GET_PROPERTY_IMAGES),
    distinctUntilChanged(),
    withLatestFrom(state$),
    switchMap((data) => {
      const getpropertyImageService =
        url +
        appConfig.backend.routes.properties.getPropertyImages.replace(
          "{0}",
          data[1].property.selectedpropertyId
        );
      return from(rest.getRequest(getpropertyImageService)).pipe(
        switchMap((response) => of(fillPropertyImages(response.data))),
        catchError((error) => {
          //TODO notification for error
          return EMPTY;
        })
      );
    })
  );

export const deletePropertyImagesEpic = (
  action$,
  state$,
  { rest, appConfig }
) =>
  action$.pipe(
    ofType(DELETE_PROPERTY_IMAGES),
    distinctUntilChanged(),
    withLatestFrom(state$),
    switchMap((data) => {
      const getpropertyImageService =
        url +
        appConfig.backend.routes.properties.deletePropertyImages;
      return from(
        rest.deleteRequest(getpropertyImageService, {
          property_image_id: data[0].payload.propertyImageId,
        })
      ).pipe(
        switchMap((response) => of(getPropertyImages())),
        catchError((error) => {
          //TODO notification for error
          return EMPTY;
        })
      );
    })
  );

export const updatePropertyImagesEpic = (
  action$,
  state$,
  { rest, appConfig }
) =>
  action$.pipe(
    ofType(UPDATE_PROPERTY_IMAGES),
    distinctUntilChanged(),
    withLatestFrom(state$),
    switchMap((data) => {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const getpropertyImageService =
        url +
        appConfig.backend.routes.properties.updatePropertyImages;
      return from(
        axios.post(
          getpropertyImageService,
          {
            image_flag: data[0].payload.imageFlag,
            image: data[0].payload.images,
            property_id: data[0].payload.propertyId,
          },
          config
        )
      ).pipe(
        switchMap((response) => of(getPropertyImages())),
        catchError((error) => {
          //TODO notification for error
          return EMPTY;
        })
      );
    })
  );

export const updatePropertyNotesEpic = (action$, state$, { rest, appConfig }) =>
  action$.pipe(
    ofType(UPDATE_PROPERTY_NOTES),
    distinctUntilChanged(),
    withLatestFrom(state$),
    switchMap((data) => {
      const getpropertyImageService =
        url +
        appConfig.backend.routes.properties.updatePropertyNotes;
      return from(
        rest.postRequest(getpropertyImageService, {
          property_id: data[0].payload.propertyId,
          notes: data[0].payload.notes,
        })
      ).pipe(
        switchMap((response) => of(fillPropertyNotes(response))),
        catchError((error) => {
          //TODO notification for error
          return EMPTY;
        })
      );
    })
  );

/**********************************************************************************************/

export const propertyEpics = combineEpics(
  getPropertyImagesEpic,
  deletePropertyImagesEpic,
  updatePropertyImagesEpic,
  updatePropertyNotesEpic,
);

import "./DisplayContactCategories.css";
import React, { useEffect, useState } from "react";
import Table from "../../../components/common/table/Table";
import { openModal } from "../../../redux/modal/modalActions";
import { useDispatch } from "react-redux";
import { getAllContactCategories } from "../../../apis/contactsApis/getAllContactCategories";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";

function DisplayContactCategories() {
  const dispatch = useDispatch();
  const [ContactCategoriesData, setContactCategoriesData] = useState([]);

  useEffect(() => {
    const getContactCategories = async () => {
      try {
        const allContactCategoriesRes = await getAllContactCategories();
        if (
          allContactCategoriesRes.error_code === 2 ||
          allContactCategoriesRes.error_code === 3
        ) {
          dispatch(
            openModal(
              <ResponseModal
                text={allContactCategoriesRes.error_message}
                isError={true}
              />
            )
          );
        } else {
          setContactCategoriesData(allContactCategoriesRes.res.data);
        }
      } catch (error) {
        console.error("Error fetching Contact Categories:", error);
      }
    };
    getContactCategories();
  }, []);

  const tableHeaders = [
    {
      key: "id",
      name: "ID",
    },
    {
      key: "name",
      name: "Name",
    },
  ];

  return (
    <>
      <strong className="strong-margin">Contact Categories List : </strong>
      <div className="table-main-container-displayContactCategories">
        <Table
          tableHeight={"350px"}
          headers={tableHeaders}
          rows={ContactCategoriesData}
        />
      </div>
    </>
  );
}

export default DisplayContactCategories;

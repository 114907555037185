import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { routeNames } from "../../../consts/routesNames";
import { navtabs } from "../../../consts/navTabs";
import { setActiveNavTab } from "../../../redux/navbar/navbarActions";
import { ValidateFields, ValidateEmail } from "../../../utils/formValidation";
import { login } from "../../../apis/authenticationApis/login";
import Button from "../../../components/common/button/Button";
import TextField from "../../../components/common/textfield/TextField";
import logo from "../../../assets/images/AO-logo-black.jpg";
import "./Login.css";

function Login() {
  const [emailState, setEmailState] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === routeNames.LOGIN) {
      dispatch(setActiveNavTab(navtabs.LOGIN));
    }
  }, [location.pathname, dispatch]);

  const formValidation = (emailState, passwordState) => {
    if (!ValidateFields(emailState)) {
      setEmailError("This Feild is required");
    } else if (!ValidateFields(passwordState)) {
      setPasswordError("This Feild is required");
    }
    if (!ValidateEmail(emailState)) {
      alert("Wrong email Format.");
      setEmailError("Wrong Email Format.");
    }

    return passwordError === null && emailError === null ? true : false;
  };

  const HandleLoginClick = async (e) => {
    e.preventDefault();

    const isValidated = formValidation(emailState, passwordState);
    if (isValidated) {
      const loginRes = await login(emailState, passwordState);
      if (loginRes.error_code === 1) {
        navigate(routeNames.HOME);
        dispatch(setActiveNavTab(navtabs.HOME));
      } else if (loginRes.error_code === 3) {
        alert(loginRes.error_message);
        setEmailState("");
        setPasswordState("");
      }
    }
  };

  const HandleRegistrations = (e) => {
    return navigate(routeNames.REGISTER);
  };

  return (
    <form onSubmit={(e) => HandleLoginClick(e)}>
      <div className="login-body-container">
        <div className="login-main-container">
          <img className="login-logo" src={logo} alt={""} />
          <div className="login-card-container">
            <div className="login-labeltext-main-container">
              <div className="login-labeltext-container">
                <TextField
                  label={"Email"}
                  name="email"
                  type="text"
                  setValue={setEmailState}
                  value={emailState}
                  placeHolder="Please Enter Your Email ...."
                  isRequired={true}
                  maxLength={100}
                />
              </div>
              <div className="login-labeltext-container">
                <TextField
                  label={"Password"}
                  name="password"
                  type="password"
                  setValue={setPasswordState}
                  value={passwordState}
                  placeHolder="Please Enter Your Password ...."
                  isRequired={true}
                  maxLength={100}
                />
              </div>
              <div className={"login-buttons-container"}>
                <div className="login-button-container">
                  <Button
                    className="login"
                    buttonText={"Login"}
                    type="submit"
                  />
                </div>
                <div className="login-button-container">
                  <Button
                    className="login"
                    buttonText={"Register"}
                    onClick={(e) => HandleRegistrations(e)}
                  />
                </div>
              </div>
              {/* <div className="login-forget-password-container">
                <a href="/login">{"Forgot Password?"}</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Login;

import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router";
import Button from "../../../components/common/button/Button";
import Table from "../../../components/common/table/Table";
import { routeNames } from "../../../consts/routesNames";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./DisplayItems.css";
import { createMenuItem } from "../../../helpers/menuHelper";
import { useDispatch } from "react-redux";
import { openModal } from "../../../redux/modal/modalActions";
import ConfirmationBox from "../../../components/common/confirmationBox/ConfirmationBox";
import { navtabs } from "../../../consts/navTabs";
import { setActiveNavTab } from "../../../redux/navbar/navbarActions";
import { getAllItems } from "../../../apis/itemsApis/getAllItems";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";
import { deleteItem } from "../../../apis/itemsApis/deleteItem";

function DisplayItems({ homePageDisplay }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [itemsData, setItemsData] = useState([]);

  useEffect(() => {
    if (location.pathname === routeNames.ITEMS) {
      dispatch(setActiveNavTab(navtabs.ITEMS));
    }
  }, [location.pathname, dispatch]);

  // === Getting Table Data  ===
  const getItems = useCallback(async () => {
    try {
      const allItemRes = await getAllItems();
      if (allItemRes.error_code === 2 || allItemRes.error_code === 3) {
        dispatch(
          openModal(
            <ResponseModal text={allItemRes.error_message} isError={true} />
          )
        );
      } else {
        let itemsArray = [];
        if (allItemRes.res.data.length > 0) {
          itemsArray = allItemRes.res.data.map((item) => ({
            ...item,
            phase_name: item.phase.name,
            sub_phase_name: item.sub_phase.name,
            unit_name: item.unit.name,
            supplier_name: `${item.supplier.first_name} ${
              item.supplier.last_name ? item.supplier.last_name : ""
            }`,
          }));
        }
        setItemsData(itemsArray);
      }
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const tableHeaders = [
    {
      key: "name",
      name: "Name",
    },
    {
      key: "type",
      name: "Type",
    },
    {
      key: "phase_name", // Needs to be handled better
      name: "Phase",
    },
    {
      key: "sub_phase_name", // Needs to be handled better
      name: "Sub Phase",
    },
    {
      key: "supplier_name", // Needs to be handled better
      name: "Supplier",
    },
    {
      key: "unit_name", // Needs to be handled better
      name: "Unit",
    },
    {
      key: "price",
      name: "Price",
    },
  ];
  // === Click Button Action Handlers ===
  const HandleItemDetailsClick = (rowData) => {
    navigate(routeNames.ITEM_DETAILS, { state: { data: rowData } });
  };

  const HandleAddNewItemClick = () => {
    navigate(routeNames.ADD_ITEM);
  };

  // === Delete Item Action ===
  const confirmDeleteItem = (rowData) => {
    dispatch(
      openModal(
        <ConfirmationBox
          msg={`Are you sure you want to delete "${rowData.name}"`}
          saveText={"OK"}
          discardText={"Cancel"}
          saveAction={async () => {
            try {
              await deleteItem(rowData.id);
              errorDispatcher("Deleted Successfully");
              getItems();
            } catch (error) {
              console.error("Error deleting item:", error);
              errorDispatcher("Failed to delete the item.");
            }
          }}
        />
      )
    );
  };

  const errorDispatcher = (message) => {
    alert(message);
    dispatch(openModal(<ResponseModal text={message} isError={true} />));
  };

  // === Edit Item Action ===
  const editItem = (rowData) => {
    navigate(`${routeNames.EDIT_ITEM.split(":")[0]}${rowData.id}`, {
      state: { data: rowData },
    });
  };

  return (
    <>
      <div
        className={
          homePageDisplay
            ? "main-container-displayItems-homePageDisplay"
            : "main-container-displayItems"
        }
      >
        {!homePageDisplay ? (
          <div className="btns-main-container-displayItems">
            <div className="add-btn-container-displayItems">
              <Button
                className={"add-btn-displayItems"}
                buttonText={"Add New Item"}
                onClick={HandleAddNewItemClick}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="table-main-container-displayItems">
          <Table
            tableHeight={homePageDisplay ? "450px" : "650px"}
            headers={tableHeaders}
            rows={itemsData}
            searchable={homePageDisplay ? false : true}
            onRowClick={HandleItemDetailsClick}
            rowActions={[
              createMenuItem("Edit", editItem, <EditIcon />),
              createMenuItem("Delete", confirmDeleteItem, <DeleteIcon />),
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default DisplayItems;

import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router";
import Button from "../../../components/common/button/Button";
import Table from "../../../components/common/table/Table";
import { routeNames } from "../../../consts/routesNames";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./DisplayContacts.css";
import { createMenuItem } from "../../../helpers/menuHelper";
import { useDispatch } from "react-redux";
import { openModal } from "../../../redux/modal/modalActions";
import ConfirmationBox from "../../../components/common/confirmationBox/ConfirmationBox";
import { navtabs } from "../../../consts/navTabs";
import { setActiveNavTab } from "../../../redux/navbar/navbarActions";
import { getAllContacts } from "../../../apis/contactsApis/getAllContacts";
import { deleteContact } from "../../../apis/contactsApis/deleteContact";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";

function DisplayContacts({ homePageDisplay }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [contactsData, setContactsData] = useState([]);

  useEffect(() => {
    if (location.pathname === routeNames.CONTACTS) {
      dispatch(setActiveNavTab(navtabs.CONTACTS));
    }
  }, [location.pathname, dispatch]);

  // === Getting Table Data  ===
  const getContacts = useCallback(async () => {
    try {
      const allContactsRes = await getAllContacts();
      if (allContactsRes.error_code === 2 || allContactsRes.error_code === 3) {
        dispatch(
          openModal(
            <ResponseModal text={allContactsRes.error_message} isError={true} />
          )
        );
      } else {
        let contactsArray = [];
        if (allContactsRes.res.data.length > 0) {
          contactsArray = allContactsRes.res.data.map((contact) => ({
            ...contact,
            full_name: `${contact.first_name} ${contact.last_name}`,
            company_name: contact.company.name,
            category_name: contact.category.name,
            created_by: `${contact.created_by.first_name} ${contact.created_by.last_name}`,
          }));
        }
        setContactsData(contactsArray);
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const tableHeaders = [
    {
      key: "full_name",
      name: "Name",
    },
    {
      key: "company_name", // Needs to be handled better
      name: "Company Name",
    },
    {
      key: "phone_number",
      name: "Phone Number",
    },
    {
      key: "email",
      name: "Email",
    },
    {
      key: "category_name", // Needs to be handled better
      name: "Category",
    },
    {
      key: "type",
      name: "Type",
    },
    {
      key: "created_by",
      name: "Created By",
    },
  ];
  // === Click Button Action Handlers ===
  const HandleContactDetailsClick = (rowData) => {
    navigate(routeNames.CONTACT_DETAILS, { state: { data: rowData } });
  };

  const HandleAddNewContactClick = () => {
    navigate(routeNames.ADD_CONTACT);
  };

  // === Delete Contact Action ===
  const confirmDeleteContact = (rowData) => {
    dispatch(
      openModal(
        <ConfirmationBox
          msg={`Are you sure you want to delete "${rowData.first_name} ${rowData.last_name}"`}
          saveText={"OK"}
          discardText={"Cancel"}
          saveAction={async () => {
            try {
              await deleteContact(rowData.id);
              errorDispatcher("Deleted Successfully");
              getContacts();
            } catch (error) {
              console.error("Error deleting contact:", error);
              errorDispatcher("Failed to delete the contact.");
            }
          }}
        />
      )
    );
  };

  const errorDispatcher = (message) => {
    alert(message);
    dispatch(openModal(<ResponseModal text={message} isError={true} />));
  };

  // === Edit Contact Action ===
  const editContact = (rowData) => {
    navigate(`${routeNames.EDIT_CONTACT.split(":")[0]}${rowData.id}`, {
      state: { data: rowData },
    });
  };

  return (
    <>
      <div
        className={
          homePageDisplay
            ? "main-container-displayContacts-homePageDisplay"
            : "main-container-displayContacts"
        }
      >
        {!homePageDisplay ? (
          <div className="btns-main-container-displayContacts">
            <div className="add-btn-container-displayContacts">
              <Button
                className={"add-btn-displayContacts"}
                buttonText={"Add New Contact"}
                onClick={HandleAddNewContactClick}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="table-main-container-displayContacts">
          <Table
            tableHeight={homePageDisplay ? "450px" : "650px"}
            headers={tableHeaders}
            rows={contactsData}
            searchable={homePageDisplay ? false : true}
            onRowClick={HandleContactDetailsClick}
            rowActions={[
              createMenuItem("Edit", editContact, <EditIcon />),
              createMenuItem("Delete", confirmDeleteContact, <DeleteIcon />),
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default DisplayContacts;

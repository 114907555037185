import urlConfig from "../../config.json";
import { BACKEND_URL } from "../../constants";
import { setCookie } from "../../utils/cookies";
import axios from "axios";

export const login = async (email, password) => {
  const ROUTE = urlConfig.backend.routes.authentication.login;
  const url = `${BACKEND_URL}${ROUTE}`;

  const reqData = {
    email: email,
    password: password,
  };

  return await axios
    .post(url, reqData)
    .then((res) => {
      if (res.status === 200) {
        setCookie("accessToken", res.data.access_token, 1);
        setCookie("refreshToken", res.data.refresh_token, 7);
        setCookie("userId", res.data.user_id, 1);
        setCookie("userEmail", res.data.email, 1);
        setCookie("firstName", res.data.first_name, 1);
        setCookie("lastName", res.data.last_name, 1);
        setCookie("phoneNumber", res.data.phone_number, 1);
        setCookie("userRole", res.data.role, 1);
        return { res: res.data, error_code: 1 };
      }
    })
    .catch((error) => {
      if (error.response.status === 400) {
        return {
          error_code: 2,
          error_message: "Email and Password fields are required",
        };
      } else if (error.response.status === 422) {
        return {
          error_code: 3,
          error_message: "Email or Password is incorrect.",
        };
      } else {
        alert(error.response.data.message);
      }
    });
};

import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/common/button/Button";
import SelectInput from "../../../components/common/selectInput/SelectInput";
import TextField from "../../../components/common/textfield/TextField";
import {
  ValidateFields,
  ValidateInputs,
  ValidateEmail,
  EMAILVALIDATION,
} from "../../../utils/formValidation";
import {
  bankAccountTypeOptions,
  contactTypeOptions,
} from "../../../consts/dropDownOptions/contactsOptions";
import UploadImageInput from "../../../components/common/uploadImageInput/UploadImageInput";
import { routeNames } from "../../../consts/routesNames";
import { useLocation, useNavigate, useParams } from "react-router";
import { createContact } from "../../../apis/contactsApis/createContact";
import { editContact } from "../../../apis/contactsApis/editContact";
import "./AddEditContacts.css";
import { useDispatch } from "react-redux";
import { openModal } from "../../../redux/modal/modalActions";
import { getCookie } from "../../../utils/cookies";
import { getAllCompanies } from "../../../apis/companiesApis/getAllCompanies";
import { getAllBanks } from "../../../apis/financesApis/getAllBanks";
import { getAllContactCategories } from "../../../apis/contactsApis/getAllContactCategories";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";

function AddEditContacts() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = getCookie("userId");

  const { id: contactId } = useParams();
  const data = location?.state?.data;
  const editable = data && Object.entries(data).length > 0;

  const [companiesData, setCompaniesData] = useState([]);
  const [contactCategoriesData, setContactCategoriesData] = useState([]);
  const [banksData, setBanksData] = useState([]);

  // === Contact Info States ===
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState({ id: "", name: "" });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [type, setType] = useState(""); // Fixed in the BE Model and FE as Options
  const [category, setCategory] = useState({ id: "", name: "" }); // Drop Down with Get Categories
  const [profilePicture, setProfilePicture] = useState("");

  // === Contact Address States ===
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");

  // === Bank Info States ===
  const [contactBank, setContactBank] = useState(""); // Drop Down with Get Banks
  const [contactBankAccountName, setContactBankAccountName] = useState("");
  const [contactBankAccountType, setContactBankAccountType] = useState(""); // Fixed in the BE Model and FE as Options
  const [contactBankAccountNumber, setContactBankAccountNumber] = useState("");
  const [contactBankRoutingNumber, setContactBankRoutingNumber] = useState("");

  // === Bank Address States ===
  const [bankStreetNumber, setBankStreetNumber] = useState("");
  const [bankStreetName, setBankStreetName] = useState("");
  const [bankCity, setBankCity] = useState("");
  const [bankState, setBankState] = useState("");
  const [bankCountry, setBankCountry] = useState("");
  const [bankZipCode, setBankZipCode] = useState("");

  const [comment, setComment] = useState("");
  const bankDataDic = useRef({});

  // Load Options to DropDown
  useEffect(() => {
    const getCompanies = async () => {
      try {
        const allCompaniesRes = await getAllCompanies();
        if (
          allCompaniesRes.error_code === 2 ||
          allCompaniesRes.error_code === 3
        ) {
          dispatch(
            openModal(
              <ResponseModal
                text={allCompaniesRes.error_message}
                isError={true}
              />
            )
          );
        } else {
          setCompaniesData(allCompaniesRes.res.data);
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };
    getCompanies();
  }, []);

  useEffect(() => {
    const getBanks = async () => {
      try {
        const allBanksRes = await getAllBanks();
        if (allBanksRes.error_code === 2 || allBanksRes.error_code === 3) {
          dispatch(
            openModal(
              <ResponseModal text={allBanksRes.error_message} isError={true} />
            )
          );
        } else {
          allBanksRes?.res?.data.map((bank) => {
            bank["value"] = bank.name;
            bankDataDic.current[bank.name] = bank;
          });
          setBanksData(allBanksRes.res.data);
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };
    getBanks();
  }, []);

  useEffect(() => {
    const getContactCategories = async () => {
      try {
        const allContactCategoriesRes = await getAllContactCategories();
        if (
          allContactCategoriesRes.error_code === 2 ||
          allContactCategoriesRes.error_code === 3
        ) {
          dispatch(
            openModal(
              <ResponseModal
                text={allContactCategoriesRes.error_message}
                isError={true}
              />
            )
          );
        } else {
          setContactCategoriesData(allContactCategoriesRes.res.data);
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };
    getContactCategories();
  }, []);

  // Set All States
  useEffect(() => {
    if (editable) {
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setCompany(data.company);
      setPhoneNumber(data.phone_number);
      setPhoneNumber2(data.phone_number_2);
      setEmail(data.email);
      setWebsite(data.website);
      setType(data.type);
      setCategory(data.category);
      setProfilePicture(data.profile_picture);

      setStreetNumber(data.street_number);
      setStreetName(data.street_name);
      setCity(data.city);
      setState(data.state);
      setCountry(data.country);
      setZipCode(data.zip_code);

      setContactBank(data?.contact_bank?.name);
      setContactBankAccountName(data.contact_bank_account_name);
      setContactBankAccountType(data.contact_bank_account_type);
      setContactBankAccountNumber(data.contact_bank_account_number);
      setContactBankRoutingNumber(data.contact_bank_routing_number);

      setBankStreetNumber(data.bank_street_number);
      setBankStreetName(data.bank_street_name);
      setBankCity(data.bank_city);
      setBankState(data.bank_state);
      setBankCountry(data.bank_country);
      setBankZipCode(data.bank_zip_code);

      setComment(data.comment);
    }
  }, []);

  // === Create - Edit ReqBody ====
  const reqBody = () => {
    const baseRequestBody = {
      first_name: firstName,
      last_name: lastName,
      company_id: company.id, // Have Logic
      phone_number: phoneNumber,
      phone_number_2: phoneNumber2,
      email: email,
      website: website,
      type: type,
      category_id: category.id, // Have Logic
      profile_picture: profilePicture,

      street_number: streetNumber,
      street_name: streetName,
      city: city,
      state: state,
      country: country,
      zip_code: zipCode,

      contact_bank_id:
        contactBank === "" ? { id: "" } : bankDataDic.current[contactBank].id, // Have Logic
      contact_bank_account_name: contactBankAccountName,
      contact_bank_account_number: contactBankAccountNumber,
      contact_bank_routing_number: contactBankRoutingNumber,
      contact_bank_account_type: contactBankAccountType,
      bank_street_number: bankStreetNumber,
      bank_street_name: bankStreetName,
      bank_city: bankCity,
      bank_state: bankState,
      bank_country: bankCountry,
      bank_zip_code: bankZipCode,
      comment: comment,
    };
    if (editable) {
      return {
        ...baseRequestBody,
        contact_id: contactId,
        updated_by_user_id: userId,
      };
    } else {
      return {
        ...baseRequestBody,
        created_by_user_id: userId,
      };
    }
  };

  // === Add Handlers ===
  const addContactHandler = async () => {
    const isValidated = addContactValidation();
    if (isValidated) {
      const createContactRes = await createContact(reqBody());
      if (createContactRes.error_code === 1) {
        alert("Contact Created Successfully");
        navigate(routeNames.CONTACTS);
      }
    }
  };

  // === Edit Handlers ===
  const editContactHandler = async (e) => {
    e.preventDefault();
    const isValidated = editContactValidation();
    if (isValidated) {
      const editContactRes = await editContact(reqBody());
      if (editContactRes.error_code === 1) {
        alert("Contact Updated Successfully");
        navigate(routeNames.CONTACTS);
      }
    }
  };

  // === Image Handler ===
  const handleImageChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  // === Form Validation ===
  const addContactValidation = () => {
    if (!ValidateFields(firstName)) {
      alert("First Name is required");
      return false;
    }
    if (!ValidateFields(phoneNumber)) {
      alert("Phone Number is required");
      return false;
    }
    if (!ValidateFields(email)) {
      alert("Email is required");
      return false;
    }
    if (!ValidateFields(type)) {
      alert("Type is required");
      return false;
    }
    if (!ValidateInputs(category)) {
      alert("Category is required");
      return false;
    }
    if (!ValidateInputs(company)) {
      alert("Company is required");
      return false;
    }
    const validateEmail = ValidateEmail(email);
    if (!validateEmail) {
      alert(EMAILVALIDATION);
      return false;
    }
    return true;
  };

  const editContactValidation = () => {
    const validateEmail = ValidateEmail(email);
    if (!validateEmail) {
      alert(EMAILVALIDATION);
      return false;
    }
    return true;
  };

  return (
    <>
      <div className="main-container-addEditContacts">
        {/* Inputs Container */}
        <div className="form-main-container-addEditContacts">
          <div className="form-row-container-addEditContacts">
            {/* Contact Info */}
            <div
              className="form-column-container-addEditContacts"
              id="contact-info"
            >
              <div className="form-column-addEditContacts">
                <div className="form-card-text-container-addEditContacts">
                  Contact Info
                </div>
                <div className="form-card-input-container-addEditContacts">
                  {/* Contatct Info Input fields */}
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"First Name"}
                      name="first_name"
                      type="text"
                      setValue={setFirstName}
                      value={firstName}
                      placeHolder=" First Name ...."
                      isRequired={true}
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Last Name"}
                      name="last_name"
                      type="text"
                      setValue={setLastName}
                      value={lastName}
                      placeHolder=" Last Name ...."
                      isRequired={true}
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <SelectInput
                      value={company.name}
                      onChange={(e, newValue) => {
                        setCompany(newValue);
                      }}
                      options={companiesData}
                      label={"Company Name"}
                      name="company_name"
                      required={true}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Phone Number"}
                      name="phone_number"
                      type="text"
                      setValue={setPhoneNumber}
                      value={phoneNumber}
                      placeHolder=" Phone Number ...."
                      isRequired={true}
                      maxLength = {32}
                      regex={/^[0-9\b\-]+$/}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Phone Number 2"}
                      name="phone_number_2"
                      type="text"
                      setValue={setPhoneNumber2}
                      value={phoneNumber2}
                      placeHolder=" Second Phone Number ...."
                      maxLength = {32}
                      regex={/^[0-9\b\-]+$/}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Email"}
                      name="email"
                      type="text"
                      setValue={setEmail}
                      value={email}
                      placeHolder=" Email ...."
                      isRequired={true}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Website"}
                      name="website"
                      type="text"
                      setValue={setWebsite}
                      value={website}
                      placeHolder=" Website ...."
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <SelectInput
                      options={contactTypeOptions}
                      label={"Contact Type"}
                      name="contact_type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      required={true}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <SelectInput
                      onChange={(e, newValue) => {
                        setCategory(newValue);
                      }}
                      options={contactCategoriesData}
                      label={"Contact Category"}
                      name="contact_category"
                      value={category.name}
                      required={true}
                    />
                  </div>
                  {!editable && (
                    <div className="labeltext-container-addEditContacts">
                      <UploadImageInput
                        name="Profile Picture"
                        label="Profile Picture"
                        onclick={(e) => handleImageChange(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Contact Address */}
            <div
              className="form-column-container-addEditContacts"
              id="contact-address"
            >
              <div className="form-column-addEditContacts">
                <div className="form-card-text-container-addEditContacts">
                  Contact Address
                </div>
                <div className="form-card-input-container-addEditContacts">
                  {/* Contact Address Input fields */}
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Street Number"}
                      name="contact_treet_number"
                      type="text"
                      setValue={setStreetNumber}
                      value={streetNumber}
                      placeHolder=" Street Number ...."
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Street Name"}
                      name="contact_street_name"
                      type="text"
                      setValue={setStreetName}
                      value={streetName}
                      placeHolder=" Street Name ...."
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"City"}
                      name="contact_city"
                      type="text"
                      setValue={setCity}
                      value={city}
                      placeHolder=" City ...."
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"State"}
                      name="contact_state"
                      type="text"
                      setValue={setState}
                      value={state}
                      placeHolder=" State ...."
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Country"}
                      name="contact_country"
                      type="text"
                      setValue={setCountry}
                      value={country}
                      placeHolder=" Country ...."
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Zip Code"}
                      name="contact_zip_code"
                      type="text"
                      setValue={setZipCode}
                      value={zipCode}
                      placeHolder=" Zip Code ...."
                      maxLength = {32}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-row-container-addEditContacts">
            {/* Bank Info */}
            <div
              className="form-column-container-addEditContacts"
              id="bank-info"
            >
              <div className="form-column-addEditContacts">
                <div className="form-card-text-container-addEditContacts">
                  Bank Info
                </div>
                <div className="form-card-input-container-addEditContacts">
                  {/* Bank Info Input fields */}
                  <div className="labeltext-container-addEditContacts">
                    <SelectInput
                      label={"Bank Name"}
                      name="bank_name"
                      options={banksData}
                      onChange={(e, newValue) => {
                        setContactBank(e.target.value);
                      }}
                      value={contactBank}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Account Name"}
                      name="account_name"
                      type="text"
                      setValue={setContactBankAccountName}
                      value={contactBankAccountName}
                      placeHolder=" Account Name ...."
                      maxLength = {100}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <SelectInput
                      label={"Bank Account Type"}
                      name="bank_account_type"
                      value={contactBankAccountType}
                      onChange={(e) =>
                        setContactBankAccountType(e.target.value)
                      }
                      options={bankAccountTypeOptions}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Account Number"}
                      name="account_number"
                      type="text"
                      setValue={setContactBankAccountNumber}
                      value={contactBankAccountNumber}
                      placeHolder=" Account Number ...."
                      maxLength = {100}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Routing Number"}
                      name="routing_number"
                      type="text"
                      setValue={setContactBankRoutingNumber}
                      value={contactBankRoutingNumber}
                      placeHolder=" Routing Number ...."
                      maxLength = {100}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Comment"}
                      name="comment"
                      type="text"
                      setValue={setComment}
                      value={comment}
                      placeHolder=" Comment ...."
                      maxLength = {255}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Bank Address */}
            <div
              className="form-column-container-addEditContacts"
              id="bank-address"
            >
              <div className="form-column-addEditContacts">
                <div className="form-card-text-container-addEditContacts">
                  Bank Address
                </div>
                <div className="form-card-input-container-addEditContacts">
                  {/* Bank Address Input fields */}
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Street Number"}
                      name="bank_street_number"
                      type="text"
                      setValue={setBankStreetNumber}
                      value={bankStreetNumber}
                      placeHolder=" Street Number ...."
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Street Name"}
                      name="bank_street_name"
                      type="text"
                      setValue={setBankStreetName}
                      value={bankStreetName}
                      placeHolder=" Street Name ...."
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"City"}
                      name="bank_city"
                      type="text"
                      setValue={setBankCity}
                      value={bankCity}
                      placeHolder=" City ...."
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"State"}
                      name="bank_state"
                      type="text"
                      setValue={setBankState}
                      value={bankState}
                      placeHolder=" State ...."
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Country"}
                      name="bank_country"
                      type="text"
                      setValue={setBankCountry}
                      value={bankCountry}
                      placeHolder=" Country ...."
                      maxLength = {32}
                    />
                  </div>
                  <div className="labeltext-container-addEditContacts">
                    <TextField
                      label={"Zip Code"}
                      name="bankzip_code"
                      type="text"
                      setValue={setBankZipCode}
                      value={bankZipCode}
                      placeHolder=" Zip Code ...."
                      maxLength = {32}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Buttons Container */}
        <div className="button-main-container-addEditContacts">
          <div className={"save-create-buttons-container"}>
            {editable ? (
              <div className="save-button-container">
                <Button
                  buttonText={"Save"}
                  onClick={(e) => editContactHandler(e)}
                />
              </div>
            ) : (
              <div className="create-button-container">
                <Button
                  buttonText={"Create"}
                  type="submit"
                  onClick={() => addContactHandler()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditContacts;

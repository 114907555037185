import apiConfig from "./config.json";

const environment = {
  development: "development",
  production: "production",
  test: "test",
};

// Get the current environment from REACT_APP_ENV
const currentEnv = process.env.REACT_APP_ENV || environment.development;

function getURLPrefix({ protocol, host, port }) {
  return `${protocol}://${host}:${port}/`;
}

let FRONTEND_URL;
let BACKEND_URL;

switch (currentEnv) {
  case environment.test:
    FRONTEND_URL = getURLPrefix({
      protocol: apiConfig.frontend.url.test.protocol,
      host: apiConfig.frontend.url.test.host,
      port: apiConfig.frontend.url.test.port,
    });
    BACKEND_URL = getURLPrefix({
      protocol: apiConfig.backend.url.test.protocol,
      host: apiConfig.backend.url.test.host,
      port: apiConfig.backend.url.test.port,
    });
    break;

  case environment.production:
    FRONTEND_URL = getURLPrefix({
      protocol: apiConfig.frontend.url.production.protocol,
      host: apiConfig.frontend.url.production.host,
      port: apiConfig.frontend.url.production.port,
    });
    BACKEND_URL = getURLPrefix({
      protocol: apiConfig.backend.url.production.protocol,
      host: apiConfig.backend.url.production.host,
      port: apiConfig.backend.url.production.port,
    });
    break;

  default:
    FRONTEND_URL = getURLPrefix({
      protocol: apiConfig.frontend.url.development.protocol,
      host: apiConfig.frontend.url.development.host,
      port: apiConfig.frontend.url.development.port,
    });
    BACKEND_URL = getURLPrefix({
      protocol: apiConfig.backend.url.development.protocol,
      host: apiConfig.backend.url.development.host,
      port: apiConfig.backend.url.development.port,
    });
    break;
}

export { BACKEND_URL, FRONTEND_URL };

import React from "react";
import PropTypes from "prop-types";
import "./TextField.css";

function TextField({
  label,
  name,
  type,
  setValue,
  isRequired,
  value,
  placeHolder,
  disabled,
  className,
  maxLength,
  regex,
}) {
  const onChange = (e) => {
    if (regex !== "") {
      if (e.target.value === "" || regex.test(e.target.value)) {
        setValue(e.target.value);
      }
    } else {
      setValue(e.target.value);
    }
  };
  return (
    <div className={`text-field-container ${className}`.trim()}>
      <label className="text-field-label">
        {label} {isRequired && "*"}
      </label>
      {type === "multilined" ? (
        <textarea
          className={"text-field-input"}
          name={name}
          required={isRequired}
          value={value}
          onChange={onChange}
          placeholder={placeHolder}
          disabled={disabled}
        />
      ) : (
        <input
          className={"text-field-input"}
          type={type}
          name={name}
          required={isRequired}
          value={value}
          onChange={onChange}
          placeholder={placeHolder}
          disabled={disabled}
          autoComplete="new-password"
          maxLength={maxLength}
        />
      )}
    </div>
  );
}

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  setValue: PropTypes.func,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  regex: PropTypes.string,
};

TextField.defaultProps = {
  label: "",
  name: "",
  type: "text",
  placeHolder: "",
  setValue: () => {},
  isRequired: false,
  value: "",
  disabled: false,
  className: "",
  maxLength: "",
  regex: "",
};
export default TextField;

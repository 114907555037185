import urlConfig from "../../config.json";
import { BACKEND_URL } from "../../constants";
import axios from "axios";

export const registerAccount = async (
  email,
  userName,
  mobileNumber,
  firstName,
  middleName,
  lastName,
  dateOfBirth,
  role,
  password
) => {
  const ROUTE = urlConfig.backend.routes.authentication.register;
  const url = `${BACKEND_URL}${ROUTE}`;

  const reqData = {
    email: email,
    username: userName,
    phone_number: mobileNumber,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    date_of_birth: dateOfBirth,
    role: role,
    password: password,
  };

  return await axios
    .post(url, reqData)
    .then((res) => {
      if (res.status === 200) {
        return { error_code: 1 };
      } else {
        return {
          error_message: "Please Check all required fields",
        };
      }
    })
    .catch((error) => {
      alert(error.response.data.message);
      return false;
    });
};

import React from "react";
import PropTypes from "prop-types";
import "./Button.css";

function Button({
  buttonText,
  className,
  onClick,
  type,
  disabled,
  customStyles,
}) {
  return (
    <button
      className={className ? className : "btn-login"}
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={customStyles}
    >
      {buttonText}
    </button>
  );
}

Button.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  customStyles: PropTypes.any,
};

Button.defaultProps = {
  buttonText: "",
  className: "",
  type: "button",
  disabled: false,
  onClick: () => {},
  customStyles: {},
};

export default Button;

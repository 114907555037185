export const propertyStatusOptions = [
  {
    name: "Active",
    value: "active",
  },
  {
    name: "Future - On Hold",
    value: "future_on_hold",
  },
  {
    name: "Finished",
    value: "finished",
  },
];

export const floorPlanTypesOptions = [
  {
    name: "Open Floor",
    value: "open_floor",
  },
  {
    name: "Formal Dinning - Great Room",
    value: "formal_dinning_great_room",
  },
];

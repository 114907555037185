import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { routeNames } from "../consts/routesNames";
import { getCookie } from "./cookies";

export default function PrivateRoutes() {
  return getCookie("accessToken") ? (
    <Outlet />
  ) : (
    <Navigate to={routeNames.LOGIN} replace />
  );
}

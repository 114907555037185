import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { routeNames } from "./consts/routesNames";
import { Provider } from "react-redux";
import PrivateRoutes from "./utils/PrivateRoutes";
import store from "./redux/store";

// Components
import GenericModal from "./components/common/modal/GenericModal";
import Navbar from "./components/navbar/Navbar";
import MobileNavbar from "./components/mobileNavbar/MobileNavbar";

// ---- Pages ---- //
// Common
import HomePage from "./pages/homepage/Homepage";
import NoPage from "./pages/common/nopage/NoPage";

// Authentication
import Register from "./pages/authentication/register/Register";
import Login from "./pages/authentication/login/Login";
import Logout from "./utils/Logout";

// Contacts
import DisplayContacts from "./pages/contacts/displayContacts/DisplayContacts";
import AddEditContacts from "./pages/contacts/addEditContacts/AddEditContacts";
import ContactDetails from "./pages/contacts/contactDetails/ContactDetails";

// Items
import DisplayItems from "./pages/items/displayItems/DisplayItems";
import AddEditItems from "./pages/items/addEditItems/AddEditItems";
import ItemDetails from "./pages/items/itemDetails/ItemDetails";

// Properties
import DisplayProperties from "./pages/properties/displayProperties/DisplayProperties";
import AddEditProperties from "./pages/properties/addEditProperties/AddEditProperties";
import PropertyDetails from "./pages/properties/propertyDetails/PropertyDetails";

// Lists
import DisplayLists from "./pages/lists/displayLists/DisplayLists";

import useWindowSize from "./hooks/useWindowSize";

function AppRoutes() {

  const { windowWidth } = useWindowSize();

  return (
    <>
      <Provider store={store}>
        <Router>
          <GenericModal />
          {windowWidth > 1260 ? <Navbar /> : <MobileNavbar />}
          <Routes>
            {/* Public Routes */}
            <Route path={routeNames.REGISTER} element={<Register />} />
            <Route path={routeNames.LOGIN} element={<Login />} />
            <Route path={routeNames.LOGOUT} element={<Logout />} />
            <Route path="*" element={<NoPage />} />
            {/* Private Routes */}
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/lists" element={<DisplayLists />} />
              {/* Contact Routes */}
              <Route path={routeNames.CONTACTS} element={<DisplayContacts />} />
              <Route
                path={routeNames.ADD_CONTACT}
                element={<AddEditContacts />}
              />
              <Route
                path={routeNames.EDIT_CONTACT}
                element={<AddEditContacts />}
              />
              <Route
                path={routeNames.CONTACT_DETAILS}
                element={<ContactDetails />}
              />
              {/* Items Routes */}
              <Route path={routeNames.ITEMS} element={<DisplayItems />} />
              <Route path={routeNames.ADD_ITEM} element={<AddEditItems />} />
              <Route path={routeNames.EDIT_ITEM} element={<AddEditItems />} />
              <Route path={routeNames.ITEM_DETAILS} element={<ItemDetails />} />
              {/* Properties Routes */}
              <Route
                path={routeNames.PROPERTIES}
                element={<DisplayProperties />}
              />
              <Route
                path={routeNames.ADD_PROPERTY}
                element={<AddEditProperties />}
              />
              <Route
                path={routeNames.EDIT_PROPERTY}
                element={<AddEditProperties />}
              />
              <Route
                path={routeNames.PROPERTY_DETAILS}
                element={<PropertyDetails />}
              />
            </Route>
          </Routes>
        </Router>
      </Provider>
    </>
  );
}

export default AppRoutes;

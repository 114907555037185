export const genderOptions = [
  {
    name: "Male",
    value: "male",
  },
  {
    name: "Female",
    value: "female",
  },
];

export const roleOptions = [
  {
    name: "Admin",
    value: "admin",
  },
  {
    name: "General Contractor",
    value: "general_contractor",
  },
  {
    name: "Project Manager",
    value: "project_manager",
  },
  {
    name: "Others",
    value: "others",
  },
];

import React from "react";
import { useNavigate } from "react-router";
import Button from "../../components/common/button/Button";
import { routeNames } from "../../consts/routesNames";
import DisplayContacts from "../contacts/displayContacts/DisplayContacts";
import DisplayItems from "../items/displayItems/DisplayItems";
import DisplayProperties from "../properties/displayProperties/DisplayProperties";
import "./Homepage.css";

function HomePage() {
  const navigate = useNavigate();

  const HandleAddNewContactClick = () => {
    navigate(routeNames.ADD_CONTACT);
  };
  const HandleAddNewItemClick = () => {
    navigate(routeNames.ADD_ITEM);
  };
  const HandleAddNewPropertyClick = () => {
    navigate(routeNames.ADD_PROPERTY);
  };

  return (
    <>
      <div>
        <div className="main-container-homepage">
          <div className="btns-main-container-homepage">
            <div className="add-btn-container-homepage">
              <Button
                className={"add-btn-homepage"}
                buttonText={"Add New Contact"}
                onClick={HandleAddNewContactClick}
              />
            </div>
            <div className="add-btn-container-homepage">
              <Button
                className={"add-btn-homepage"}
                buttonText={"Add New Item"}
                onClick={HandleAddNewItemClick}
              />
            </div>
            <div className="add-btn-container-homepage">
              <Button
                className={"add-btn-homepage"}
                buttonText={"Add New Project"}
                onClick={HandleAddNewPropertyClick}
              />
            </div>
          </div>

          <div className="table-main-container-homepage">
            <strong className="strong-margin red">Closed Projects : </strong>
            <DisplayProperties
              homePageDisplay={true}
              projectStatus={"finished"}
            />
            <strong className="strong-margin green">Active Projects : </strong>
            <DisplayProperties
              homePageDisplay={true}
              projectStatus={"active"}
            />
            <strong className="strong-margin blue">Future Projects : </strong>
            <DisplayProperties
              homePageDisplay={true}
              projectStatus={"future_on_hold"}
            />
            <strong className="strong-margin">Contacts List : </strong>
            <DisplayContacts homePageDisplay={true} />
            <strong className="strong-margin">Items List : </strong>
            <DisplayItems homePageDisplay={true} />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie, deleteCookie } from "./cookies";

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie("accessToken")) {
      deleteCookie("accessToken");
      deleteCookie("lastName");
      deleteCookie("firstName");
      deleteCookie("refreshToken");
      deleteCookie("phoneNumber");
      deleteCookie("email");
      navigate("/login");
    }
    navigate("/login");
  });
  return <div></div>;
}

import {
  FILL_PROPERTY_IMAGES,
  FETCH_PROPERTY,
  DELETE_PROPERTY_IMAGES,
  UPDATE_PROPERTY_IMAGES,
  FILL_PROPERTY_NOTES,
  UPDATE_PROPERTY_NOTES,
} from "./projectTypes";

const initialState = {
  isLoading: true,
  propertyselected: null,
  selectedpropertyId: null,
  propertyImages: [],
  propertyNotes: ""
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPERTY:
    case UPDATE_PROPERTY_NOTES:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_PROPERTY_IMAGES:
    case UPDATE_PROPERTY_IMAGES:
      return {
        ...state,
        selectedpropertyId: action.payload.propertyId,
      };

    case FILL_PROPERTY_IMAGES:
      return {
        ...state,
        isLoading: false,
        propertyImages: action.payload.data,
      };

    case FILL_PROPERTY_NOTES:
      return {
        ...state,
        isLoading: false,
        propertyNotes: action.payload.data,
      };

    default:
      return state;
  }
};

export default propertyReducer;

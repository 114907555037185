import "./DisplayPhases.css";
import React, { useEffect, useState } from "react";
import Table from "../../../components/common/table/Table";
import { openModal } from "../../../redux/modal/modalActions";
import { useDispatch } from "react-redux";
import { getAllSubPhases } from "../../../apis/phasesApis/getAllSubPhases";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";

function DisplayPhases() {
  const dispatch = useDispatch();
  const [phasesData, setPhasesData] = useState([]);

  useEffect(() => {
    const getPhases = async () => {
      try {
        const allPhasesRes = await getAllSubPhases();
        if (allPhasesRes.error_code === 2 || allPhasesRes.error_code === 3) {
          dispatch(
            openModal(
              <ResponseModal text={allPhasesRes.error_message} isError={true} />
            )
          );
        } else {
          const transformedData = allPhasesRes.res.data.map((subPhase) => ({
            id: subPhase.id,
            name: subPhase.name,
            phaseId: subPhase.phase.id,
            phaseName: subPhase.phase.name,
          }));
          setPhasesData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching Phases:", error);
      }
    };
    getPhases();
  }, []);

  const tableHeaders = [
    {
      key: "phaseId",
      name: "Phase ID",
    },
    {
      key: "phaseName",
      name: "Phase Name",
    },
    {
      key: "id",
      name: "Sub Phase ID",
    },
    {
      key: "name",
      name: "Sub Phase Name",
    },
  ];

  return (
    <>
      <strong className="strong-margin">Phases List : </strong>
      <div className="table-main-container-displayPhases">
        <Table tableHeight={"350px"} headers={tableHeaders} rows={phasesData} />
      </div>
    </>
  );
}

export default DisplayPhases;

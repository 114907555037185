import "./DisplayUsers.css";
import React, { useEffect, useState } from "react";
import Table from "../../../components/common/table/Table";
import { openModal } from "../../../redux/modal/modalActions";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../../apis/authenticationApis/getAllUsers";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";
import { roleOptions } from "../../../consts/dropDownOptions/registrationOptions";

function DisplayUsers() {
  const dispatch = useDispatch();
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const allUsersRes = await getAllUsers();
        if (allUsersRes.error_code === 2 || allUsersRes.error_code === 3) {
          dispatch(
            openModal(
              <ResponseModal text={allUsersRes.error_message} isError={true} />
            )
          );
        } else {
          setUsersData(allUsersRes.res.data);
        }
      } catch (error) {
        console.error("Error fetching Users:", error);
      }
    };
    getUsers();
  }, []);

  const tableHeaders = [
    {
      key: "username",
      name: "Username",
    },
    {
      key: "fullName",
      name: "Full Name",
    },
    {
      key: "email",
      name: "Email",
    },
    {
      key: "phone_number",
      name: "Phone Number",
    },
    {
      key: "role",
      name: "Role",
    },
  ];

  const formattedUsersData = usersData.map((user) => ({
    ...user,
    fullName: `${user.first_name} ${user.middle_name || ""} ${
      user.last_name
    }`.trim(),
    role:
      roleOptions.find((option) => option.value === user.role)?.name ||
      user.role,
  }));

  return (
    <>
      <strong className="strong-margin">Users List : </strong>
      <div className="table-main-container-displayUsers">
        <Table
          tableHeight={"350px"}
          headers={tableHeaders}
          rows={formattedUsersData}
        />
      </div>
    </>
  );
}

export default DisplayUsers;

import React from "react";
import PropTypes from "prop-types";
import "./ResponseModal.css";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorIcon from "@mui/icons-material/Error";

function ResponseModal({ text, isError }) {
  return (
    <div className="response-modal-container">
      {isError ? (
        <ErrorIcon fontSize="large" color="error" />
      ) : (
        <DoneAllIcon fontSize="large" color="success" />
      )}
      <div className="response-modal-message">{text}</div>
    </div>
  );
}

ResponseModal.propTypes = {
  text: PropTypes.string,
  isError: PropTypes.bool,
};

ResponseModal.defaultProps = {
  text: "",
  isError: false,
};

export default ResponseModal;

import "./DisplayCompanies.css";
import React, { useEffect, useState } from "react";
import Table from "../../../components/common/table/Table";
import { openModal } from "../../../redux/modal/modalActions";
import { useDispatch } from "react-redux";
import { getAllCompanies } from "../../../apis/companiesApis/getAllCompanies";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";

function DisplayCompanies() {
  const dispatch = useDispatch();
  const [companiesData, setCompaniesData] = useState([]);

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const allCompaniesRes = await getAllCompanies();
        if (
          allCompaniesRes.error_code === 2 ||
          allCompaniesRes.error_code === 3
        ) {
          dispatch(
            openModal(
              <ResponseModal
                text={allCompaniesRes.error_message}
                isError={true}
              />
            )
          );
        } else {
          setCompaniesData(allCompaniesRes.res.data);
        }
      } catch (error) {
        console.error("Error fetching companies :", error);
      }
    };
    getCompanies();
  }, []);

  const tableHeaders = [
    {
      key: "id",
      name: "ID",
    },
    {
      key: "name",
      name: "Name",
    },
  ];

  return (
    <>
      <strong className="strong-margin">Companies List : </strong>
      <div className="table-main-container-displayCompanies">
        <Table
          tableHeight={"350px"}
          headers={tableHeaders}
          rows={companiesData}
        />
      </div>
    </>
  );
}

export default DisplayCompanies;

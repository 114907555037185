import React from "react";
import DisplayBanks from "../displayBanks/DisplayBanks";
import DisplayCompanies from "../displayCompanies/DisplayCompanies";
import DisplayContactCategories from "../displayContactCategories/DisplayContactCategories";
import DisplayPhases from "../displayPhases/DisplayPhases";
import DisplayPropertyTypes from "../displayPropertyTypes/DisplayPropertyTypes";
import DisplayUnits from "../displayUnits/DisplayUnits";
import DisplayUsers from "../displayUsers/DisplayUsers";
import "./DisplayLists.css";

function DisplayLists() {
  return (
    <>
      <div>
        <div className="main-container-homepage">
          <DisplayCompanies />
          <DisplayBanks />
          <DisplayUnits />
          <DisplayPropertyTypes />
          <DisplayContactCategories />
          <DisplayPhases />
          <DisplayUsers />
        </div>
      </div>
    </>
  );
}

export default DisplayLists;

import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import Button from "../../../components/common/button/Button";
import SelectInput from "../../../components/common/selectInput/SelectInput";
import TextField from "../../../components/common/textfield/TextField";
import UploadImageInput from "../../../components/common/uploadImageInput/UploadImageInput";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";
import { ValidateFields, ValidateInputs } from "../../../utils/formValidation";
import {
  propertyStatusOptions,
  floorPlanTypesOptions,
} from "../../../consts/dropDownOptions/propertiesOptions";
import { routeNames } from "../../../consts/routesNames";
import "./AddEditProperties.css";
import { openModal } from "../../../redux/modal/modalActions";
import { getCookie } from "../../../utils/cookies";
// API Calls
import { createProperty } from "../../../apis/propertiesApis/createProperty";
import { editProperty } from "../../../apis/propertiesApis/editProperty";
import { getAllPropertyTypes } from "../../../apis/propertiesApis/getAllPropertyTypes";
import Input from "../../../components/common/input/Input";

function AddEditProperties() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = getCookie("userId");

  const { id: PropertyId } = useParams();
  const data = location?.state?.data;
  const editable = data && Object.entries(data).length > 0;

  const [PropertyTypesData, setPropertyTypesData] = useState([]);

  // === Project Address States ===
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [county, setCounty] = useState("");
  const [subDivision, setSubDivision] = useState("");
  const [section, setSection] = useState("");
  const [block, setBlock] = useState("");
  const [lot, setLot] = useState("");

  // === Project Info States ===
  const [startDate, setStartDate] = useState(null);
  const [closeDate, setCloseDate] = useState(null);
  const [type, setType] = useState({ id: "", name: "" }); // Drop Down with Get property types
  const [status, setStatus] = useState(""); // Fixed in the BE Model and FE as Options
  const [completionPercentage, setCompletionPercentage] = useState(0);

  // === Project Area States ===
  const [livingSQFT, setLivingSQFT] = useState("");
  const [totalSQFT, setTotalSQFT] = useState("");
  const [stories, setStories] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [officesStudios, setOfficesStudios] = useState("");
  const [pools, setPools] = useState("");
  const [garages, setGarages] = useState("");
  const [casita, setCasita] = useState("");
  const [mediaRoom, setMediaRoom] = useState("");
  const [gameRoom, setGameRoom] = useState("");

  // === Project Governmental States ===
  const [installedGasMeterSize, setInstalledGasMeterSize] = useState("");
  const [waterAccountName, setWaterAccountName] = useState("");
  const [waterAccountNumber, setWaterAccountNumber] = useState("");
  const [electricityAccountName, setElectricityAccountName] = useState("");
  const [electricityAccountNumber, setElectricityAccountNumber] = useState("");
  const [gasAccountName, setGasAccountName] = useState("");
  const [gasAccountNumber, setGasAccountNumber] = useState("");

  // === Project Extra Fields States ===
  const [notes, setNotes] = useState("");
  const [MLS, setMLS] = useState("");
  const [HCAD, setHCAD] = useState("");
  const [googleMapsLink, setGoogleMapsLink] = useState("");
  const [floorPlanType, setFloorPlanType] = useState(""); // Fixed in the BE Model and FE as Options
  const [profileImage, setProfileImage] = useState("");
  const [propertyImage, setPropertyImage] = useState([]);

  // Load Options to DropDown
  useEffect(() => {
    const getPropertyTypes = async () => {
      try {
        const allPropertyTypesRes = await getAllPropertyTypes();
        if (
          allPropertyTypesRes.error_code === 2 ||
          allPropertyTypesRes.error_code === 3
        ) {
          dispatch(
            openModal(
              <ResponseModal
                text={allPropertyTypesRes.error_message}
                isError={true}
              />
            )
          );
        } else {
          setPropertyTypesData(allPropertyTypesRes.res.data);
        }
      } catch (error) {
        console.error("Error fetching Projects:", error);
      }
    };
    getPropertyTypes();
  }, []);

  // Set All States
  useEffect(() => {
    if (editable) {
      setStreetNumber(data.street_number);
      setStreetName(data.street_name);
      setCity(data.city);
      setState(data.state);
      setCountry(data.country);
      setZipCode(data.zip_code);
      setCounty(data.county);
      setSubDivision(data.sub_division);
      setSection(data.section);
      setBlock(data.block);
      setLot(data.lot);

      setStartDate(data.start_date);
      setCloseDate(data.close_date);
      setType(data.type);
      setStatus(data.status);
      setCompletionPercentage(data.completion_percentage);

      setLivingSQFT(data.living_sq_ft);
      setTotalSQFT(data.total_sq_ft);
      setStories(data.stories);
      setBedrooms(data.bedrooms);
      setBathrooms(data.bathrooms);
      setOfficesStudios(data.offices_studios);
      setPools(data.pools);
      setGarages(data.garages);
      setCasita(data.casita);
      setMediaRoom(data.media_room);
      setGameRoom(data.game_room);

      setInstalledGasMeterSize(data.installed_gas_meter_size);
      setWaterAccountName(data.water_account_name);
      setWaterAccountNumber(data.water_account_number);
      setElectricityAccountName(data.electricity_account_name);
      setElectricityAccountNumber(data.electricity_account_number);
      setGasAccountName(data.gas_account_name);
      setGasAccountNumber(data.gasAccountNumber);

      setNotes(data.notes);
      setMLS(data.MLS);
      setHCAD(data.HCAD);
      setGoogleMapsLink(data.google_maps_link);
      setFloorPlanType(data.floor_plan_type);
    }
  }, []);

  // === Create - Edit ReqBody ====
  const reqBody = () => {
    const baseRequestBody = {
      street_number: streetNumber,
      street_name: streetName,
      city: city,
      state: state,
      country: country,
      zip_code: zipCode,
      county: county,
      sub_division: subDivision,
      section: section,
      block: block,
      lot: lot,

      start_date: startDate,
      close_date: closeDate,
      type_id: type.id, // Have Logic
      status: status,

      living_sq_ft: livingSQFT,
      total_sq_ft: totalSQFT,
      stories: stories,
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      offices_studios: officesStudios,
      pools: pools,
      garages: garages,
      casita: casita,
      media_room: mediaRoom,
      game_room: gameRoom,

      installed_gas_meter_size: installedGasMeterSize,
      water_account_name: waterAccountName,
      water_account_number: waterAccountNumber,
      electricity_account_name: electricityAccountName,
      electricity_account_number: electricityAccountNumber,
      gas_account_name: gasAccountName,
      gas_account_number: gasAccountNumber,

      notes: notes,
      MLS: MLS,
      HCAD: HCAD,
      google_maps_link: googleMapsLink,
      floor_plan_type: floorPlanType,
    };
    if (editable) {
      return {
        ...baseRequestBody,
        property_id: PropertyId,
        updated_by_user_id: userId,
      };
    } else {
      return {
        ...baseRequestBody,
        property_main_picture: profileImage,
        property_images: propertyImage,
        created_by_user_id: userId,
      };
    }
  };

  const handleImageChange = (e, type) => {
    switch (type) {
      case "single":
        setProfileImage(e.target.files[0]);
        break;

      case "mutiple":
        const arrayOfObjects = Object.keys(e.target.files).map(
          (key) => e.target.files[key]
        );
        setPropertyImage(arrayOfObjects);
        break;

      default:
        break;
    }
  };

  // === Add Handlers ===
  const addPropertyHandler = async () => {
    const isValidated = addEditPropertyValidation();
    if (isValidated) {
      const createPropertyRes = await createProperty(reqBody());
      if (createPropertyRes?.error_code === 1) {
        alert("Project Created Successfully");
        navigate(routeNames.PROPERTIES);
      }
    }
  };

  // === Edit Handlers ===
  const editPropertyHandler = async (e) => {
    e.preventDefault();
    const isValidated = addEditPropertyValidation();
    if (isValidated) {
      const editPropertyRes = await editProperty(reqBody());
      if (editPropertyRes?.error_code === 1) {
        alert("Project Updated Successfully");
        navigate(routeNames.PROPERTIES);
      }
    }
  };

  // === Form Validation ===
  const addEditPropertyValidation = () => {
    if (!ValidateFields(streetNumber)) {
      alert("Street Number is required");
      return false;
    }
    if (!ValidateFields(streetName)) {
      alert("Street Name is required");
      return false;
    }
    if (!ValidateFields(city)) {
      alert("City is required");
      return false;
    }
    if (!ValidateFields(state)) {
      alert("State is required");
      return false;
    }
    if (!ValidateFields(country)) {
      alert("Country is required");
      return false;
    }
    if (!ValidateFields(zipCode)) {
      alert("Zip Code is required");
      return false;
    }
    if (!ValidateInputs(type)) {
      alert("Type is required");
      return false;
    }
    return true;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div className="main-container-addEditProperties">
        {/* Inputs Container */}
        <div className="form-main-container-addEditProperties">
          <div className="form-row-container-addEditProperties">
            {/* Project Address */}
            <div
              className="form-column-container-addEditProperties"
              id="Project-address"
            >
              <div className="form-column-addEditProperties">
                <div className="form-card-text-container-addEditProperties">
                  Project Address
                </div>
                <div className="form-card-input-container-addEditProperties">
                  {/* Project Address Input fields */}
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Street Number"}
                      name="street_number"
                      type="text"
                      setValue={setStreetNumber}
                      value={streetNumber}
                      placeHolder=" Street Number ...."
                      isRequired={true}
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Street Name"}
                      name="street_name"
                      type="text"
                      setValue={setStreetName}
                      value={streetName}
                      placeHolder=" Street Name ...."
                      isRequired={true}
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"City"}
                      name="ity"
                      type="text"
                      setValue={setCity}
                      value={city}
                      placeHolder=" City ...."
                      isRequired={true}
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"State"}
                      name="state"
                      type="text"
                      setValue={setState}
                      value={state}
                      placeHolder=" State ...."
                      isRequired={true}
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Country"}
                      name="country"
                      type="text"
                      setValue={setCountry}
                      value={country}
                      placeHolder=" Country ...."
                      isRequired={true}
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Zip Code"}
                      name="zip_code"
                      type="text"
                      setValue={setZipCode}
                      value={zipCode}
                      placeHolder=" Zip Code ...."
                      isRequired={true}
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"County"}
                      name="county"
                      type="text"
                      setValue={setCounty}
                      value={county}
                      placeHolder=" County ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Sub Division"}
                      name="sub_division"
                      type="text"
                      setValue={setSubDivision}
                      value={subDivision}
                      placeHolder=" Sub Division ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Section"}
                      name="section"
                      type="text"
                      setValue={setSection}
                      value={section}
                      placeHolder=" Section ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Block"}
                      name="block"
                      type="text"
                      setValue={setBlock}
                      value={block}
                      placeHolder=" Block ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Lot"}
                      name="lot"
                      type="text"
                      setValue={setLot}
                      value={lot}
                      placeHolder=" Lot ...."
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Project Info and Extra Fields */}
            <div
              className="form-column-container-addEditProperties"
              id="Project-info-extra-fields"
            >
              <div className="form-column-addEditProperties">
                <div className="form-card-text-container-addEditProperties">
                  Project Info
                </div>
                <div className="form-card-input-container-addEditProperties">
                  {/* Project Info Input fields */}
                  <div className="labeltext-container-addEditProperties">
                    <Input
                      label={"Start Date"}
                      name="start_date"
                      type="date"
                      setValue={setStartDate}
                      max={getCurrentDate()}
                      value={startDate}
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <Input
                      label={"Close Date"}
                      name="close_date"
                      type="date"
                      setValue={setCloseDate}
                      max={getCurrentDate()}
                      value={closeDate}
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <SelectInput
                      onChange={(e, newValue) => {
                        setType(newValue);
                      }}
                      options={PropertyTypesData}
                      label={"Type"}
                      name="type"
                      value={type.name}
                      required={true}
                      disabled={editable ? true : false}
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <SelectInput
                      options={propertyStatusOptions}
                      label={"Status"}
                      name="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Completion Percentage"}
                      name="completion_percentage"
                      type="text"
                      setValue={setCompletionPercentage}
                      value={
                        isNaN(completionPercentage) ||
                        completionPercentage == null
                          ? "0.00%"
                          : `${completionPercentage.toFixed(2)}%`
                      }
                      disabled={true}
                      placeHolder=" Completion Percentage ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Notes"}
                      name="notes"
                      type="text"
                      setValue={setNotes}
                      value={notes}
                      placeHolder=" Notes ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"MLS"}
                      name="MLS"
                      type="text"
                      setValue={setMLS}
                      value={MLS}
                      placeHolder=" MLS ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"HCAD"}
                      name="HCAD"
                      type="text"
                      setValue={setHCAD}
                      value={HCAD}
                      placeHolder=" HCAD ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Google Maps Link"}
                      name="google_maps_link"
                      type="text"
                      setValue={setGoogleMapsLink}
                      value={googleMapsLink}
                      placeHolder=" Google Maps Link ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <SelectInput
                      options={floorPlanTypesOptions}
                      label={"Floor Plan Type"}
                      name="floor_plan_type"
                      value={floorPlanType}
                      onChange={(e) => setFloorPlanType(e.target.value)}
                    />
                  </div>
                  {!editable && (
                    <>
                      <div className="labeltext-container-addEditProperties">
                        <UploadImageInput
                          name="Main Project Image"
                          label="Main Project Image"
                          onclick={(e) => handleImageChange(e, "single")}
                        />
                      </div>
                      <div className="labeltext-container-addEditProperties">
                        <UploadImageInput
                          name="Project Images"
                          label="Project Images"
                          multiple
                          onclick={(e) => handleImageChange(e, "mutiple")}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="form-row-container-addEditProperties">
            {/* Project Area */}
            <div
              className="form-column-container-addEditProperties"
              id="property-area"
            >
              <div className="form-column-addEditProperties">
                <div className="form-card-text-container-addEditProperties">
                  Project Area
                </div>
                <div className="form-card-input-container-addEditProperties">
                  {/* Project Area Input fields */}
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Living SQFT"}
                      name="living_sq_ft"
                      type="text"
                      setValue={setLivingSQFT}
                      value={livingSQFT}
                      placeHolder=" Living SQFT ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Total SQFT"}
                      name="total_sq_ft"
                      type="text"
                      setValue={setTotalSQFT}
                      value={totalSQFT}
                      placeHolder=" Total SQFT ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Stories"}
                      name="stories"
                      type="text"
                      setValue={setStories}
                      value={stories}
                      placeHolder=" Stories ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Bedrooms"}
                      name="bedrooms"
                      type="text"
                      setValue={setBedrooms}
                      value={bedrooms}
                      placeHolder=" Bedrooms ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Bathrooms"}
                      name="bathrooms"
                      type="text"
                      setValue={setBathrooms}
                      value={bathrooms}
                      placeHolder=" Bathrooms ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Office Studios"}
                      name="office_studios"
                      type="text"
                      setValue={setOfficesStudios}
                      value={officesStudios}
                      placeHolder=" Office Studios ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Pools"}
                      name="pools"
                      type="text"
                      setValue={setPools}
                      value={pools}
                      placeHolder=" Pools ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Garages"}
                      name="garages"
                      type="text"
                      setValue={setGarages}
                      value={garages}
                      placeHolder=" Garages ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Casita"}
                      name="casita"
                      type="text"
                      setValue={setCasita}
                      value={casita}
                      placeHolder=" Casita ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Media Room"}
                      name="media_room"
                      type="text"
                      setValue={setMediaRoom}
                      value={mediaRoom}
                      placeHolder=" Media Room ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Game Room"}
                      name="game_room"
                      type="text"
                      setValue={setGameRoom}
                      value={gameRoom}
                      placeHolder=" Game Room ...."
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Project Governmental */}
            <div
              className="form-column-container-addEditProperties"
              id="property-governmental"
            >
              <div className="form-column-addEditProperties">
                <div className="form-card-text-container-addEditProperties">
                  Project Governmental
                </div>
                <div className="form-card-input-container-addEditProperties">
                  {/* Project Governmental Input fields */}
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Installed Gas Meter Size"}
                      name="installed_gas_meter_size"
                      type="text"
                      setValue={setInstalledGasMeterSize}
                      value={installedGasMeterSize}
                      placeHolder=" Installed Gas Meter Size ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Water Account Name"}
                      name="water_Account_name"
                      type="text"
                      setValue={setWaterAccountName}
                      value={waterAccountName}
                      placeHolder=" Water Account Name ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Water Account Number"}
                      name="water_Account_number"
                      type="text"
                      setValue={setWaterAccountNumber}
                      value={waterAccountNumber}
                      placeHolder=" Water Account Number ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Electricity Account Name"}
                      name="electricity_Account_name"
                      type="text"
                      setValue={setElectricityAccountName}
                      value={electricityAccountName}
                      placeHolder=" Electricity Account Name ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Electricity Account Number"}
                      name="electricity_Account_number"
                      type="text"
                      setValue={setElectricityAccountNumber}
                      value={electricityAccountNumber}
                      placeHolder=" Electricity Account Number ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Gas Account Name"}
                      name="gas_Account_name"
                      type="text"
                      setValue={setGasAccountName}
                      value={gasAccountName}
                      placeHolder=" Gas Account Name ...."
                    />
                  </div>
                  <div className="labeltext-container-addEditProperties">
                    <TextField
                      label={"Gas Account Number"}
                      name="gas_Account_number"
                      type="text"
                      setValue={setGasAccountNumber}
                      value={gasAccountNumber}
                      placeHolder=" Gas Account Number ...."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Buttons Container */}
        <div className="button-main-container-addEditProperties">
          <div className={"save-create-buttons-container"}>
            {editable ? (
              <div className="save-button-container">
                <Button
                  buttonText={"Save"}
                  onClick={(e) => editPropertyHandler(e)}
                />
              </div>
            ) : (
              <div className="create-button-container">
                <Button
                  buttonText={"Create"}
                  type="submit"
                  onClick={() => addPropertyHandler()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditProperties;

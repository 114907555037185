import React, { useState } from "react";
import "./ItemDetails.css";
import myImage from "../../../assets/images/house.png";
import trash from "../../../assets/images/trash.svg";
import { useLocation } from "react-router";
import Button from "../../../components/common/button/Button";
import restHelper from "../../../helpers/RestHelper";
import appConfig from "../../../config.json";
import axios from "axios";
import { BACKEND_URL } from "../../../constants";

function ItemDetails() {
  const backEndUrl = `${BACKEND_URL}`;

  const location = useLocation();
  const data = location?.state?.data;

  const [itemImage, setItemImage] = useState(
    location?.state?.data.item_picture
  );

  const handleImageChange = (e) => {
    if (e.target.files[0]) changeImages(e.target.files[0]);
  };

  const deleteImage = () => {
    changeImages();
  };

  const changeImages = (image) => {
    const url = backEndUrl + appConfig.backend.routes.items.updateItemImages;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(
        url,
        {
          item_id: location?.state?.data?.id,
          item_picture: image ? image : {},
        },
        config
      )
      .then(() => {
        setItemImage(image ? URL.createObjectURL(image) : null);
      });
  };

  return (
    <>
      <div className="main-container-itemDetails">
        <div className="section-main-container-itemDetails">
          {/* 1st Row Item info */}
          <div className="section-row-container-itemDetails">
            <div className="section-container-itemDetails">
              <div className="section-container-itemDetails-itemInfo">
                {/* item Picture */}
                <div className="column-itemInfo-item-picture">
                  <div>
                    <img
                      src={itemImage ? itemImage : myImage}
                      alt="Item Picture"
                    />
                  </div>
                  <div className="image-controls">
                    <label class="image-btn">
                      <input
                        type="file"
                        id="propertyImage"
                        accept="image/*,.pdf"
                        onChange={(e) => handleImageChange(e)}
                      />
                      Edit Image
                    </label>
                    {itemImage !== null ? (
                      <Button
                        className={"delete-image-btn-items"}
                        buttonText={
                          <img
                            style={{ height: "75%" }}
                            src={trash}
                            alt="Delete Image"
                          />
                        }
                        onClick={() => deleteImage()}
                      />
                    ) : null}
                  </div>
                </div>
                {/* item Info */}
                <div className="column-itemInfo">
                  <h2> {data.name} </h2>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Name:</strong> {data.name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Phase:</strong> {data.phase_name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Sub Phase:</strong> {data.sub_phase_name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Supplier:</strong> {data.supplier_name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Item Type:</strong> {data.type}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Price:</strong> {data.price}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Unit:</strong> {data.unit_name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>SKU:</strong> {data.SKU}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemDetails;

import "./DisplayBanks.css";
import React, { useEffect, useState } from "react";
import Table from "../../../components/common/table/Table";
import { openModal } from "../../../redux/modal/modalActions";
import { useDispatch } from "react-redux";
import { getAllBanks } from "../../../apis/financesApis/getAllBanks";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";

function DisplayBanks() {
  const dispatch = useDispatch();
  const [banksData, setBanksData] = useState([]);

  useEffect(() => {
    const getBanks = async () => {
      try {
        const allBanksRes = await getAllBanks();
        if (allBanksRes.error_code === 2 || allBanksRes.error_code === 3) {
          dispatch(
            openModal(
              <ResponseModal text={allBanksRes.error_message} isError={true} />
            )
          );
        } else {
          setBanksData(allBanksRes.res.data);
        }
      } catch (error) {
        console.error("Error fetching Banks:", error);
      }
    };
    getBanks();
  }, []);

  const tableHeaders = [
    {
      key: "id",
      name: "ID",
    },
    {
      key: "name",
      name: "Name",
    },
  ];

  return (
    <>
      <strong className="strong-margin">Banks List : </strong>
      <div className="table-main-container-displayBanks">
        <Table tableHeight={"350px"} headers={tableHeaders} rows={banksData} />
      </div>
    </>
  );
}

export default DisplayBanks;
